.Input {
	background-color: transparent;
	border: 1px solid #ccc;
	padding: 10px;
	color: #FFF;
	/* width: 80vw; */
}

.InputWrapper{
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.InputLabel{
	text-align: left;
}
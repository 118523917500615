.NullWalletAsset{
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.NullWalletActions{
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: auto;
	width: auto;
	justify-content: space-between;
	width: 20%;
}

.NullWalletActionButton{
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.NullWalletActionImage{
	height: 30px;
}

.NullWalletTxns{
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
}
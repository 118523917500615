.KineticKeyHomeWrapper {
	display: flex;
	flex-direction: column;
	gap: 25px;
	padding: 0 20px; /* Add padding for smaller screens */
}

.KineticKeyHome {
	display: flex;
	flex-direction: column;
	gap: 100px;
	margin-top: 150px;
	align-items: center; /* Center content */
	text-align: center;
}

.KineticKeyHomeHeading {
	font-size: 1.2em;
	font-weight: 600;
	color: #FFF;
}

.KineticKeyHomeDescription {
	width: 100%;
	max-width: 500px;
	margin: auto;
	font-size: 1em;
	line-height: 1.5;
}

.KineticKeyHomeForm {
	display: flex;
	flex-direction: column;
	gap: 35px;
	width: 100%;
	max-width: 450px;
	margin: auto;
}

.KineticKeyHomeFormWrapper {
	display: flex;
	flex-direction: column;
	gap: 100px;
	width: 100%;
}

.ResultWrapper {
	display: flex;
	width: 100%;
	max-width: 500px;
	flex-direction: column;
	margin: auto;
}

.ResultText {
	display: block;
	width: 100%;
	max-width: 500px;
	margin: auto;
	white-space: normal;
	word-break: break-word;
	overflow-wrap: break-word;
	font-weight: 500;
}

.Results {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 40px;
}

/* Responsive Styles */
@media (max-width: 768px) {
	.KineticKeyHome {
		gap: 50px;
		margin-top: 100px;
	}

	.KineticKeyHomeDescription,
	.ResultWrapper,
	.ResultText {
		width: 90%;
	}

	.KineticKeyHomeForm {
		width: 90%;
	}

	.KineticKeyHomeFormWrapper {
		gap: 50px;
	}
}

@media (max-width: 480px) {
	.KineticKeyHome {
		gap: 30px;
		margin-top: 80px;
	}

	.KineticKeyHomeHeading {
		font-size: 1em;
	}

	.KineticKeyHomeDescription {
		font-size: 0.9em;
	}

	.KineticKeyHomeForm {
		width: 100%;
		gap: 25px;
	}

	.KineticKeyHomeFormWrapper {
		gap: 30px;
	}

	.ResultText {
		font-size: 0.9em;
	}
}

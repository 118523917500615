.KineticKeysWhitepaperWrapper img{
	background-color: #FFF;
	padding: 10px;
}

.KineticKeysWhitepaperWrapper{
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
}
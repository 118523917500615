.App {
	text-align: center;
	color: #FFF;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.table-container {
	overflow-x: auto;
	max-width: 100%;
	margin: auto;
}

table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 100px;
}

th {
	width: 300px;
	padding: 10px;
}

td {
	padding: 15px;
}


@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
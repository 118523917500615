.DecodeKeyData {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: 100px auto;
	width: 100%;
	max-width: 600px;
	padding: 0 20px;
	/* Prevents edge touching on small screens */
	text-align: center;
}

.DecodeKeyDataInput {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	max-width: 500px;
	margin: auto;
	margin-top: 75px;
}

.DecodeKeyDetectionResult {
	color: greenyellow;
	font-size: 1.1em;
	text-align: center;
	word-break: break-word;
	overflow-wrap: break-word;
}

/* Responsive Styles */
@media (max-width: 768px) {
	.DecodeKeyData {
		margin: 80px auto;
	}

	.DecodeKeyDataInput {
		width: 90%;
		margin-top: 50px;
	}
}

@media (max-width: 480px) {
	.DecodeKeyData {
		margin: 60px auto;
	}

	.DecodeKeyDataInput {
		width: 100%;
		margin-top: 40px;
	}

	.DecodeKeyDetectionResult {
		font-size: 1em;
	}
}
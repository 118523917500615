.CreateUnlockHash {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 500px;
	margin: auto;
	padding: 0 20px;
	/* Prevents touching screen edges on small screens */
}

.CreateUnlockHashDataWrapper {
	display: flex;
	flex-direction: column;
	gap: 50px;
	width: 100%;
}

.CreateUnlockHashDataInput {
	display: flex;
	flex-direction: column;
	gap: 35px;
	margin-top: 50px;
	width: 100%;
}

.UnlockHashTextWrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;
	width: 100%;
}

.DecodeKeyDetectionResultButtons {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	max-width: 400px;
	margin: auto;
}

.DecodeKeyDetectionResult {
	font-size: 1em;
	font-weight: 500;
	text-align: center;
	word-break: break-word;
	overflow-wrap: break-word;
}

/* Responsive Styles */
@media (max-width: 768px) {
	.CreateUnlockHash {
		width: 90vw;
	}

	.CreateUnlockHashDataInput {
		gap: 25px;
		margin-top: 40px;
	}

	.UnlockHashTextWrapper {
		gap: 30px;
	}

	.DecodeKeyDetectionResultButtons {
		width: 90%;
	}
}

@media (max-width: 480px) {
	.CreateUnlockHash {
		width: 100%;
	}

	.CreateUnlockHashDataWrapper {
		gap: 30px;
	}

	.CreateUnlockHashDataInput {
		gap: 20px;
		margin-top: 30px;
	}

	.UnlockHashTextWrapper {
		gap: 20px;
	}

	.DecodeKeyDetectionResultButtons {
		width: 100%;
		gap: 15px;
	}

	.DecodeKeyDetectionResult {
		font-size: 0.9em;
	}
}